import React from "react";

import { useQuery } from "@tanstack/react-query";
import { getMerchantOperationHours } from "../../utils/requests-v2";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import Layout from "../../components/layout/V2/Layout";
import UpdateWorkingHoursForm from "../../components/V2/UpdateWorkingHoursForm";
import Spinner from "../../components/Spinner";

export default function SetWorkingHoursPage() {
  const { merchantId } = useAuth();

  const { data: workingHours } = useQuery(
    ["/working-hours", merchantId],
    () => getMerchantOperationHours(),
    {
      enabled: !!merchantId,
    }
  );

  return (
    <Layout header headerTitle="Configure Operation Hours">
      <div className="pt-20 pb-0 px-4 min-h-screen max-w-screen-xl container mx-auto scroll-smooth overflow-y-auto">
        {!workingHours ? (
          <div className="h-full w-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col items-center h-[calc(100vh-112px)] md:h-[70vh] container max-w-[400px] mx-auto">
            <p className="text-sm text-center">
              Set your working hours to help customers know when you are
              available
            </p>

            <UpdateWorkingHoursForm workingHours={workingHours} />
          </div>
        )}
      </div>
    </Layout>
  );
}
