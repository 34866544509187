import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";

import { useAuth } from "./AuthenticationContext";
import {
  getMerchantFacebookAds,
  listConnectedFacebookPages,
} from "../../requests-v2";
import { getMerchantReviewsKE } from "../../requests";

const BusinessContext = createContext(null);

function useBusinessContext() {
  return useContext(BusinessContext);
}

// The BusinessProvider is used to store the business context, eg countryCode, name, logo, etc
function BusinessProvider({ children }) {
  const [business, setBusiness] = useState({
    connectedFacebookPages: {
      data: [],
      isLoading: true,
    },
  });

  const { isAuthenticated, merchantId, user } = useAuth();

  const updateBusiness = (data) => {
    setBusiness((state) => ({
      ...state,
      ...data,
    }));
  };

  const clearBusinessContext = () => {
    setBusiness({});
  };

  const { data: reviews } = useQuery(
    ["/reviews", merchantId, user?.join_code],
    () =>
      getMerchantReviewsKE({
        join_code: user?.join_code,
        show_number: true,
      }),
    { enabled: !!isAuthenticated }
  );

  // Get merchant ads and update business context
  const { data: adsData } = useQuery(
    ["/facebook-ads", merchantId],
    () => getMerchantFacebookAds(),
    { enabled: !!isAuthenticated }
  );

  const { data: connectedFacebookPages, isLoading: isLoadingFacebookPages } =
    useQuery(
      ["/facebook-pages", merchantId],
      async () =>
        listConnectedFacebookPages().then((response) => response.data.items),
      { enabled: !!isAuthenticated }
    );

  useEffect(() => {
    if (adsData) {
      updateBusiness({
        facebookAdsInsights: adsData,
      });
    }
  }, [adsData, merchantId]);

  useEffect(() => {
    if (reviews) {
      updateBusiness({
        reviews,
      });
    }
  }, [reviews, merchantId]);

  useEffect(() => {
    if (connectedFacebookPages) {
      updateBusiness({
        connectedFacebookPages: {
          data: connectedFacebookPages,
          isLoading: isLoadingFacebookPages,
        },
      });
    }
  }, [connectedFacebookPages, isLoadingFacebookPages, merchantId]);

  const value = useMemo(() => ({
    updateBusiness,
    business,
    clearBusinessContext,
  }));

  console.log("business", business);

  return (
    <BusinessContext.Provider value={value}>
      {children}
    </BusinessContext.Provider>
  );
}

export { useBusinessContext, BusinessProvider };

BusinessProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
